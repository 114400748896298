import React, { Component } from "react";
import "./Agegate.scss";
import SVG from "react-inlinesvg";

import logoAnimation from "../inline-svg/bravazzi-logo-animation.svg";

class Agegate extends Component {
  constructor(props) {
    super(props);
    this.state = { imageStatus: "animationLoading" };
  }
  handleImageLoaded() {
    this.setState({ imageStatus: "animationLoaded" });
  }

  handleImageErrored() {
    this.setState({ imageStatus: "animationFailed" });
  }
  render() {
    return (
      <div id="ageGateCont" className={this.state.imageStatus}>
        <div className="logoCont">
          <SVG
            src={logoAnimation}
            width="285"
            height="200"
            uniquifyIDs={false}
            wrapper={React.createFactory("div")}
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageErrored.bind(this)}
          />

          <div id="ageGateForm">
            <h2>ARE YOU 21 YEARS OF AGE?</h2>
            <button onClick={this.props.make21} id="ageGate_yes">
              Yes
            </button>
            <button id="ageGate_no" onClick={this.props.makeUnder21}>
              No
            </button>
          </div>
          <p id="sorryMessage">
            Sorry, you must be of legal drinking age to access our site.
            <br />
            You are now being redirected to{" "}
            <a href="responsibility.org">responsibility.org</a> to learn more.
          </p>
          <p />
        </div>
      </div>
    );
  }
}

export default Agegate;
