import React, { Component } from "react";
import "./Reviews.scss";

const quote = process.env.REACT_APP_CDN + "quote.svg";

class Reviews extends Component {
  render() {
    return (
      <section className="reviews">
        <div id="reviewSlider">
          <div className="review container" id="review1">
            <div className="quote">
              <span>
                <img className="quote" src={quote} alt="" />
                One sip and I knew—I found my go-to summer drink.
                <img className="endQuote" src={quote} alt="" />
              </span>
            </div>
            <div className="attribution">
              <div className="name">Maddie C.</div>
              <div className="location">Nashville, TN</div>
            </div>
          </div>
          <div className="review container" id="review2">
            <div className="quote">
              <span>
                <img className="quote" src={quote} alt="" />
                The smell. The taste. Wow is the only word to describe it.
                <img className="endQuote" src={quote} alt="" />
              </span>
            </div>
            <div className="attribution">
              <div className="name">Leah H.</div>
              <div className="location">Louisville, KY</div>
            </div>
          </div>
          <div className="review container" id="review3">
            <div className="quote">
              <span>
                <img className="quote" src={quote} alt="" />
                It’s fresh. Not too sweet. You can tell it’s made differently,
                in a good way.
                <img className="endQuote" src={quote} alt="" />
              </span>
            </div>
            <div className="attribution">
              <div className="name">Sam B.</div>
              <div className="location">Naples, FL</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Reviews;
