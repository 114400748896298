import React, { Component } from "react";
import "./Recipe.scss";

class Recipe extends Component {
  render() {
    return (
      <div className="recipe">
        <h3>{this.props.recipe.name}</h3>
        <ul className="recipe__description">
          {this.props.recipe.desc.map((item, key) => {
            return <li key={key}>{item}</li>;
          })}
        </ul>
      </div>
    );
  }
}

export default Recipe;
