import React, { Component } from "react";
import "./Footer.scss";
import vivifyLogo from "../images/created-by-vivify-beverages.svg";

const contactFacebook = process.env.REACT_APP_CDN + "contact-facebook.svg";
// const contactTwitter = process.env.REACT_APP_CDN + "contact-twitter.svg";
const contactInstagram = process.env.REACT_APP_CDN + "contact-instagram.svg";
const contactEmail = process.env.REACT_APP_CDN + "contact-email.svg";
const footerLogo = process.env.REACT_APP_CDN + "footer-logo.svg";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div className="footer">
        <div className="contactBar">
          <div className="container">
            <div className="contactFlex">
              <span>Stay in Touch</span>
              <a href="https://www.facebook.com/drinkbravazzi/">
                <img src={contactFacebook} alt="Bravazzi on Facebook" />
              </a>
              {/* <a href="https://twitter.com/DrinkBravazzi">
                 <img src={contactTwitter} alt="Bravazzi on Twitter" />
             </a> */}
              <a href="https://www.instagram.com/drinkbravazzi/">
                <img src={contactInstagram} alt="Bravazzi on Instagram" />
              </a>
              <a href="mailto:Bravazzi@vivbev.com">
                <img src={contactEmail} alt="Email Bravazzi" />
              </a>
            </div>
          </div>
        </div>
        <footer className="container">
          <div className="footerLogoCont">
            <img
              className="footerLogo"
              src={footerLogo}
              alt="Bravazzi Hard Italian Soda logo"
            />
          </div>
          <div className="rightBank">
            <a className="vivifyLogo" href="http://vivifybeverages.com">
              <img
                src={vivifyLogo}
                alt="Bravazzi is created by Vivify Beverages"
              />
            </a>
          </div>
          <p className="legal">©{currentYear} Bravazzi</p>
        </footer>
      </div>
    );
  }
}

export default Footer;
