import React, { Component } from "react";
import Header from "./Header";
import Saluti from "./Saluti";
import Simple from "./Simple";
import Flavors from "./Flavors";
import recipeData from "../recipes";
import Recipes from "./Recipes";
import Reviews from "./Reviews";
import Locator from "./Locator";
import Footer from "./Footer";
import Agegate from "./Agegate";
import AlertBanner from "./AlertBanner";
import Modalert from "./Modalert";
import "./App.scss";
import BrandStory from "./BrandStory";
import tenbestaward from "../images/10best-award.png"
class App extends Component {
  state = {
    user: {},
    isUnder21: false,
    showingModal: false,
    showingAlertBanner: false
  };
  componentDidMount() {
    const previousVerification = localStorage.getItem("verified21");
    if (previousVerification != null) {
      let timeSinceVerification = Date.now() - previousVerification;
      const refreshTime = 864000000; //10 Days
      // const refreshTime = 10000; //10 Seconds for testing
      //check if time since verification is less than 10 days
      if (timeSinceVerification < refreshTime) {
        let userAgeVerification = {
          verified21: previousVerification
        };
        this.setState({ user: userAgeVerification });
        this.openModalert();
        this.openAlertBanner();
      }
    }

    //Conversant pixel
      const cnvrScript = document.createElement("script");
      const randomNum = Math.floor(Math.random() * 100000000)
      cnvrScript.src = `https://login.dotomi.com/profile/visit/js/1_0?dtm_cid=80615&dtm_cmagic=9edb6b&dtm_fid=2717&cachebuster=${randomNum}`;
      cnvrScript.async = true;
      // console.log(cnvrScript.src)
      document.body.appendChild(cnvrScript);
  }
  openModalert = () => {
    setTimeout(() => {
      this.setState({ showingModal: true });
    }, 2000);
  };
  openAlertBanner = () => {
    setTimeout(() => {
      this.setState({ showingAlertBanner: true });
    }, 2000);
  };
  closeModalert = () => {
    this.setState({ showingModal: false });
  };
  closeAlertBanner = () => {
    this.setState({ showingAlertBanner: false });
  };
  make21 = () => {
    let userAgeVerification = {
      verified21: Date.now()
    };
    localStorage.setItem("verified21", userAgeVerification.verified21);
    this.setState({ user: userAgeVerification });
    this.openModalert();
    this.openAlertBanner();
  };
  redirectResponsibly = () => {
    window.location.href = "http://www.responsibility.org";
  };
  makeUnder21 = () => {
    this.setState({ isUnder21: true });
    setTimeout(this.redirectResponsibly, 5000);
  };

  render() {
    const ageGateClass = this.state.user.verified21
      ? " ageGateHidden"
      : " ageGateShowing";
    const under21Class = this.state.isUnder21
      ? " under21"
      : " awaitingVerification";
    return (
      <div className={"App" + ageGateClass + under21Class}>
        {/* <AlertBanner  showing={this.state.showingAlertBanner}
          closeAlertBanner={this.closeAlertBanner}>
          <img src={tenbestaward} alt="10 Best Award"/>
          <h2 className={`heading`}>Bravazzi Crowned #2 Canned Beverage in America</h2></AlertBanner> */}
        <Header />
        <Saluti />
        <Simple />
        <Flavors />
        <Recipes
          bloodorangeRecipes={recipeData.bloodorange}
          clementinaRecipes={recipeData.clementina}
          grapefruitRecipes={recipeData.grapefruit}
          limonataRecipes={recipeData.limonata}
        />
        <BrandStory />
        <Reviews />
        <Locator />
        <Footer />
        <Modalert
          showing={this.state.showingModal}
          closeModalert={this.closeModalert}
        />
        <Agegate make21={this.make21} makeUnder21={this.makeUnder21} />
      </div>
    );
  }
}

export default App;
