import React from "react";
import "./AlertBanner.scss";
import SVG from "react-inlinesvg";
import close from "../inline-svg/close.svg";

function AlertBanner(props){
  
  return (
    <section className={`alertBanner ${props.showing ? "isShowing" : ""}`}>
      {props.children}
      <button className={`closer`} onClick={props.closeAlertBanner}>
          <SVG
            src={close}           
            uniquifyIDs={true}
            
          />
          </button>
    </section>
  );

}

export default AlertBanner;
