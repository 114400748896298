import React, { Component } from "react";
import "./Flavors.scss";

const clGlass = process.env.REACT_APP_CDN + "clementina-in-glass.jpg";
const clHeadline = process.env.REACT_APP_CDN + "clementina-ribbon.svg";
const boGlass = process.env.REACT_APP_CDN + "blood-orange-in-glass.jpg";
const boHeadline = process.env.REACT_APP_CDN + "blood-orange-ribbon.svg";
const lmGlass = process.env.REACT_APP_CDN + "limonata-in-glass.jpg";
const lmHeadline = process.env.REACT_APP_CDN + "limonata-ribbon.svg";
const gfGlass = process.env.REACT_APP_CDN + "grapefruit-in-glass.jpg";
const gfHeadline = process.env.REACT_APP_CDN + "grapefruit-ribbon.svg";

class Flavors extends Component {
  render() {
    return (
      <section className="container" id="sect4">
        <div className="flavor-grid">
          <div className="flavor-card" id="cl">
            <h2>
              <img src={clHeadline} alt="Clementina" />
            </h2>

            <img
              className="canGlass"
              src={clGlass}
              alt="Bravazzi Clementina in a glass"
            />

            <p>
              The fragrant essence of freshly-peeled citrus, with a kiss of
              subtle sweetness
            </p>
          </div>

          <div className="flavor-card" id="bo">
            <h2>
              <img src={boHeadline} alt="Blood Orange" />
            </h2>

            <img
              className="canGlass"
              src={boGlass}
              alt="Bravazzi Blood Orange in a glass"
            />

            <p>
              A burst of distinctly bold, yet delightfully sweet citrus flavor.
            </p>
          </div>
          <div className="flavor-card" id="lm">
            <h2>
              <img src={lmHeadline} alt="Limonata" />
            </h2>

            <img
              className="canGlass"
              src={lmGlass}
              alt="Bravazzi Limonata in a glass"
            />

            <p>
              The crisp, clean bite of real lemon with a touch of natural
              sweetness.
            </p>
          </div>

          <div className="flavor-card" id="gf">
            <h2>
              <img src={gfHeadline} alt="Grapefruit" />
            </h2>

            <img
              className="canGlass"
              src={gfGlass}
              alt="Bravazzi Grapefruit in a glass"
            />

            <p>
              An invigorating blend of tart and tangy that’s refreshingly
              bittersweet.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Flavors;
