import React from "react";
import "./BrandStory.scss";
import YouTube from "react-youtube";
import RibbonHeadline from "./RibbonHeadline"
import BrandStoryHero from "./BrandStoryHero"
import italy from "../inline-svg/italy.svg"
import ffLogo from "../images/female-founded-logo.svg";

function BrandStory(props){
  const opts = {
    modestbranding: 1,
    height: "",
    width: "",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0
    }
  };

  return (
    <section className="brandStory-section" style={{backgroundImage:`url(${italy})`}}>
      <BrandStoryHero />
      <div className="container padTop">
        <RibbonHeadline text="Inspired by Italy, Made in America." />
        {/* <img className="fruitrait" src={fruitrait} /> */}
        <h3>FACT: The world’s first and only hard Italian soda.  </h3>
<h3>FACT: Created by Italy obsessed females, for you, with amore. </h3>
<p></p>
<p>Like many of our favorite things, Bravazzi was inspired by a vacation to Italy. Many, many vacations to Italy…and semesters abroad. We’re obsessed. We go every chance we get. We love everything about it—the vibrancy, the culture, the open-air markets, the unwavering dedication to using only the best, freshest ingredients. Oh Italia…we could go on and on. </p>

<p>And when we couldn’t physically get there, we would still go…through our taste buds. We’d take our favorite gourmet Italian sodas, and add booze. Mmm, yum. One day, the lightbulb went off—what if we could take the authentic Italian soda flavor experience and make it boozy? The answer was yes. Yes, we could. So, we did.  </p>
 


<p>Like the Italians, we started with the best ingredients. We brewed pure sugarcane so Bravazzi is 100% gluten free. We used real juice and natural ingredients to get four unique varieties that burst with sun-ripened, true-to-fruit citrus flavor. We tinkered and tweaked until each flavor was 100% deliziosa. The result? Bravazzi—the world’s first (and only!) hard Italian soda. Try it. We hope you’ll love it as much as we do.</p>
{/* <h4>Bravazzi is our love letter to Italy.</h4> */}
<img className="ffLogo" src={ffLogo} alt="Female Founded Logo" />
</div>
<div className="container container--slim">

        <div className="videoRatio">
          <YouTube
            videoId="d8gqptHmJqo" // defaults -> null
            className="youTubeiFrame" // defaults -> null
            height=""
            width=""
            opts={opts} // defaults -> {}
          />
        </div>
      </div>
    </section>
  );

}

export default BrandStory;
