const recipes = {
  bloodorange: [
    {
      name: "Blood Orange Spritz",
      desc: ["1 part Campari", "4 parts Bravazzi Blood Orange"],
      status: "active"
    },
    {
      name: "Bravazzi Old Fashioned",
      desc: ["1 part bourbon", "4 parts Bravazzi Blood Orange"],
      status: "active"
    },
    {
      name: "Blood Orange Punch",
      desc: ["1 part citrus vodka", "4 parts Bravazzi Blood Orange"],
      status: "active"
    }
  ],
  grapefruit: [
    {
      name: "Grapefruit Mimosa",
      desc: ["1 part Ruffino Prosecco", "1 part Bravazzi Grapefruit"],
      status: "active"
    },
    {
      name: "Bravazzi Paloma",
      desc: ["1 part tequila", "4 parts Bravazzi Grapefruit"],
      status: "active"
    },
    {
      name: "Bravazzi Cordial",
      desc: ["1 part elderflower liqueur", "3 parts Bravazzi Grapefruit"],
      status: "active"
    }
  ],
  clementina: [
    {
      name: "Clementina Creamsicle",
      desc: ["1 part vanilla vodka", "4 parts Bravazzi Clementina"],
      status: "active"
    },
    {
      name: "Clementina Spritz",
      desc: ["1 part Ruffino Prosecco", "1 part Bravazzi Clementina"],
      status: "active"
    },
    {
      name: "Clementina Aperol Spritz",
      desc: ["1 part Aperol", "3 parts Bravazzi Clementina"],
      status: "active"
    }
  ],
  limonata: [
    {
      name: "Limonata Fizz",
      desc: ["1 part gin", "4 parts Bravazzi Limonata"],
      status: "active"
    },
    {
      name: "Limonata Pimms",
      desc: ["1 part Pimms", "3 parts Bravazzi Limonata"],
      status: "active"
    },
    {
      name: "Vodka Limonata",
      desc: ["1 part Citrus or regular vodka", "4 parts Bravazzi Limonata"],
      status: "active"
    }
  ]
};

export default recipes;
