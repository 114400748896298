import React, { Component } from "react";
import "./Header.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";

const heroCans = process.env.REACT_APP_CDN + "heroCans.png";
const sunburst = process.env.REACT_APP_CDN + "hero-sunburst.svg";
const heroRibbon = process.env.REACT_APP_CDN + "heroRibbon.svg";
const discover = process.env.REACT_APP_CDN + "discover-bravazzi.svg";

class Header extends Component {
  render() {
    return (
      <header className="header showingApp" id="hero">
        <div id="neatHero">
          <div id="heroBurstCont">
            <img id="heroBurst" src={sunburst} alt="" />
          </div>
        </div>
        <div className="neat">
          <img
            width="1440"
            height="614"
            id="heroCans"
            src={heroCans}
            alt="Bravazzi Cans"
          />
        </div>
        <div id="ribbonCont">
          <div id="heroRibbonCont">
            <img
              className="heroRibbon"
              id="heroRibbon1"
              src={heroRibbon}
              alt="Bravazzi Ribbon"
            />
            <img
              className="heroRibbon"
              id="heroRibbon2"
              src={heroRibbon}
              alt="Ciao!"
            />
          </div>
        </div>
        <div className="discCont">
          <AnchorLink href="#sect2">
            <img src={discover} alt="Discover Bravazzi" />
          </AnchorLink>
        </div>
      </header>
    );
  }
}

export default Header;
