import React, { Component } from "react";
import "./Simple.scss";

const simpleHeader = process.env.REACT_APP_CDN + "simple-ingredients.svg";
const cane = process.env.REACT_APP_CDN + "cane-sugar.svg";
const fruit = process.env.REACT_APP_CDN + "fruit.svg";
const bubbles = process.env.REACT_APP_CDN + "bubbles.svg";

class Simple extends Component {
  render() {
    return (
      <section className="simple" id="sect3">
        <div className="container">
          <div className="row">
            <h2>
              <img
                id="simpleIngredients"
                src={simpleHeader}
                alt="Made With Simple Ingredients"
              />
            </h2>
          </div>

          <div className="row" id="iconRow">
            <div className="col-sm-4" id="cane">
              <img
                className="ingredientIcon"
                src={cane}
                alt="Made from cane sugar."
              />
              <h3 className="lemon">
                A NEW
                <br />
                KIND OF BREW.
              </h3>
              <p className="cheat">
                Our unique cane sugar brewing process makes Bravazzi light
                tasting and naturally gluten free—so fresh flavor always shines
                through.
              </p>
            </div>
            <div className="col-sm-4" id="fruit">
              <img
                className="ingredientIcon"
                src={fruit}
                alt="Made from cane sugar."
              />
              <h3 className="grapefruit">
                BLENDED WITH
                <br />
                REAL FRUIT JUICE.
              </h3>
              <p>
                Naturally sweet from the start, each flavor is made with the
                juices of perfectly ripe fruit.
              </p>
            </div>
            <div className="col-sm-4" id="bubbles">
              <img
                className="ingredientIcon"
                src={bubbles}
                alt="Made from cane sugar."
              />
              <h3 className="bloodorange">
              A UNIQUE <br/>ITALIAN EXPERIENCE.

              </h3>
              <p>
              A refreshing blend of simple ingredients finishes light, bright, and totally delicious—in true Italian fashion.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Simple;
