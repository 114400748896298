import React from "react";
import styles from "./RibbonHeadline.module.scss";
import widower from "./Widower";

function RibBack(props){
    return <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 68"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="10242.315" y1="130" x2="10326.274" y2="130" gradientTransform="matrix(-1 0 0 1 10326.315 -96)"><stop offset="0" stop-color="#b33026"/><stop offset="1" stop-color="#e63e30"/></linearGradient><path d="M84 0H.04l21.624 34.136L.04 68H84V0z" fill="url(#a)"/><linearGradient id="b" gradientUnits="userSpaceOnUse" x1="61.471" y1="119.063" x2="97.826" y2="140.053" gradientTransform="translate(0 -96)"><stop offset="0" stop-color="#94281f"/><stop offset="1" stop-color="#b33026"/></linearGradient><path d="M84 0v68L45.852 50.116 84 0z" fill="url(#b)"/></svg>
}
function RibbonHeadline(props){
    return(
        <div className={`${styles.ribbonHeadlineComponent} ${props.className}`}>
        <RibBack className={styles.ribStart} />
        <RibBack className={styles.ribEnd} />
        <h2 className={styles.ribbonHeadline}> 
        {widower(props.text)}
        </h2>
        </div>
    )
}

export default RibbonHeadline;
