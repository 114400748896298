import React, { Component } from "react";
import "./Saluti.scss";
import SVG from "react-inlinesvg";

import bubblesLeft from "../inline-svg/bubbles_left.svg";
import bubblesRight from "../inline-svg/bubbles_right.svg";

const saluti = process.env.REACT_APP_CDN + "saluti.svg";
const glutenFree = process.env.REACT_APP_CDN + "gluten-free.svg";
const abv = process.env.REACT_APP_CDN + "abv.svg";
const nothingArtificial = process.env.REACT_APP_CDN + "nothing-artificial.svg";
const glass = process.env.REACT_APP_CDN + "glass.png";

class Saluti extends Component {
  render() {
    return (
      <section id="sect2" className="sect2">
        <SVG
          src={bubblesLeft}
          uniquifyIDs={false}
          wrapper={React.createFactory("div")}
        />

        <SVG
          src={bubblesRight}
          uniquifyIDs={false}
          wrapper={React.createFactory("div")}
        />

        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-push-4">
              <h2>
                <img className="saluti" src={saluti} alt="Saluti" />
                <span>FRESH CITRUS.</span> <span>CANE SUGAR.</span>{" "}
                <span>CRISP BUBBLES.</span>
              </h2>
              <p>
                Inspired by gourmet Italian sodas bursting with sun-ripened
                citrus flavor, Bravazzi™ excites the senses with its naturally
                sweet, fizzy essence and fruity aroma. That’s how we came up
                with the name Bravazzi: Brava (Italian for good) + Fizzi. It’s a
                refreshingly tasty, gluten-free hard soda with 4.2% alcohol—one
                sip and the world is your piazza.
              </p>
              <img
                className="wow rubberBand statBubble"
                src={glutenFree}
                alt="Gluten Free"
              />
              <img
                className="statBubble"
                src={abv}
                alt="4.2% Alcohol by Volume"
              />
              <img
                className="statBubble"
                src={nothingArtificial}
                alt="Nothing Artificial"
              />
            </div>
            <div className="col-sm-4 col-sm-pull-8">
              <img id="glass" src={glass} alt="Glass of Bravazzi" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Saluti;
