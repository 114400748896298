import React, { Component } from "react";
import "./Locator.scss";
import ReactIframeResizer from "react-iframe-resizer-super";

const locatorHeadline = process.env.REACT_APP_CDN + "find-bravazzi-ribbon.svg";
const packaging = process.env.REACT_APP_CDN + "bravazzi-packaging-2018.jpg";

class Locator extends Component {
  render() {
    let states = [
      "North Carolina",
      "South Carolina",
      "Virginia",
      "Washington DC",
      "Georgia",
      "Ohio",
      "Indiana",
      "Minnesota",
      "Illinois",
      "Tennessee",
      "Kentucky",
      "Rhode Island",
      "Massachusetts",
      "Connecticut",
      "Michigan",
      "New York",
      "Pennsylvania",
      "New Jersey",
      "Colorado",
      "North Dakota",
      "Texas"
    ];
    states = states.sort();
    return (
      <section id="locator">
        <div className="container">
          <h2>
            <img id="findRibbon" src={locatorHeadline} alt="Find Bravazzi" />
          </h2>
          <div className="row">
            <div className="col-sm-8 col-sm-push-2">
              <p>
                <a
                  className="btn btn-primary btn-bravazzi"
                  href="https://drizly.com/beer-brands/bravazzi/b11560"
                >
                  Buy Bravazzi Online ▸
                </a>
              </p>
              <p>Bravazzi is currently available in the following states:</p>
              <ul className="locations">
                {states.map((state, i) => {
                  console.log("Entered");
                  // Return the element. Also pass key
                  return <li key={i}>{state}</li>;
                })}
              </ul>

              <p>Bravazzi is growing quickly, so check back for updates!</p>

              <div className="iframePad">
                {/* <ReactIframeResizer src="https://www.vtinfo.com/PF/product_finder.asp?doFocus=0&amp;custID=VFY&amp;theme=bs-simplex&amp;category1=bravazzi" /> */}
                <ReactIframeResizer src="https://finder.vtinfo.com/finder/web/v2/iframe?doFocus=0&amp;custID=VFY&amp;theme=bs-simplex&amp;category1=bravazzi" />
              </div>
            </div>
          </div>
        </div>
        <div className="sixPacks">
          <div className="container">
            <img
              className=""
              src={packaging}
              alt="Bravazzi comes 6 packs of individual flavors or a 12 can variety pack"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Locator;
