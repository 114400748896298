import React, { Component } from "react";
import "./Modalert.scss";
//import ffLogo from "../images/female-founded-logo.svg";
import SVG from "react-inlinesvg";
import close from "../inline-svg/close.svg";
const david = process.env.REACT_APP_CDN + "David-in-Circle.jpg";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { imageStatus: "animationLoading" };
  }
  render() {
    return (
      <div className={`modalert ${this.props.showing ? "showing" : ""}`}>
        <div onClick={this.props.closeModalert} className="shade"></div>
        <div className="content">
          <img className="david" src={david} alt="Female Founded Logo" />
          <h2>
            TRAVEL TO ITALY <br/>FROM HOME
          </h2>

          <p className="announcement">
            <strong  className="subhead">
              You could WIN A SCOOTER OR ONE OF FIVE MONTHLY{" "}
              <span className="dontBreak">$200 CASH PRIZES!</span>
            </strong>
            <span className="desktopOnly">Vacation plans may be on hold, but don’t put away your travel camera
            just yet. Post an Instagram photo of how you envision enjoying a
            Bravazzi in Italy and you could win a piece of it.</span>
          </p>
          <div className="ctas">
            <button
              onClick={this.props.closeModalert}
              className="btn btn-secondary btn-bravazzi"
            >
              Dismiss
            </button>
            <a className="btn btn-primary btn-bravazzi" href="/rules">
              See Details
            </a>
          </div>
          <button className={`closer`} onClick={this.props.closeModalert}>
          <SVG
            src={close}           
            uniquifyIDs={true}
            
          />
          </button>
        </div>
      </div>
    );
  }
}

export default Modal;
