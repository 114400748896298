import React, { Component } from "react";
import Recipe from "./Recipe";
import "./Recipes.scss";

const recipeHeadline = process.env.REACT_APP_CDN + "cocktail-recipes.svg";
const bloodorangeIcon = process.env.REACT_APP_CDN + "bloodorange-icon.svg";
const limonataIcon = process.env.REACT_APP_CDN + "limonata-icon.svg";
const grapefruitIcon = process.env.REACT_APP_CDN + "grapefruit-icon.svg";
const clementinaIcon = process.env.REACT_APP_CDN + "clementina-icon.svg";

class Recipes extends Component {
  render() {
    return (
      <section className="recipeSection">
        <div className="container">
          <h2>
            <img
              className="ribbonHeadline"
              src={recipeHeadline}
              alt="Cocktail Recipes"
            />
          </h2>
          <div className="recipe-grid">
            <div className="recipe-grid__flavor clementinaRecipes">
              <div className="recipe-grid__flavorCard">
                <img
                  className="recipeIcon"
                  src={clementinaIcon}
                  alt="Clementina Recipes"
                />
                {Object.keys(this.props.clementinaRecipes).map(key => (
                  <Recipe
                    key={key}
                    index={key}
                    recipe={this.props.clementinaRecipes[key]}
                  />
                ))}
              </div>
            </div>
            <div className="recipe-grid__flavor bloodorangeRecipes">
              <div className="recipe-grid__flavorCard">
                <img
                  className="recipeIcon"
                  src={bloodorangeIcon}
                  alt="Clementina Recipes"
                />
                {Object.keys(this.props.bloodorangeRecipes).map(key => (
                  <Recipe
                    key={key}
                    index={key}
                    recipe={this.props.bloodorangeRecipes[key]}
                  />
                ))}
              </div>
            </div>
            <div className="recipe-grid__flavor limonataRecipes">
              <div className="recipe-grid__flavorCard">
                <img
                  className="recipeIcon"
                  src={limonataIcon}
                  alt="Clementina Recipes"
                />
                {Object.keys(this.props.limonataRecipes).map(key => (
                  <Recipe
                    key={key}
                    index={key}
                    recipe={this.props.limonataRecipes[key]}
                  />
                ))}
              </div>
            </div>
            <div className="recipe-grid__flavor grapefruitRecipes">
              <div className="recipe-grid__flavorCard">
                <img
                  className="recipeIcon"
                  src={grapefruitIcon}
                  alt="Clementina Recipes"
                />
                {Object.keys(this.props.grapefruitRecipes).map(key => (
                  <Recipe
                    key={key}
                    index={key}
                    recipe={this.props.grapefruitRecipes[key]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Recipes;
