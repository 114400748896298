import React from "react";
import "./BrandStoryHero.scss";
import AS from "../images/Sarah-Ross-Anne-Ellis.png";
import LL from "../images/love-letter.jpg";
import RibbonHeadline from "./RibbonHeadline";
import LLText from "../images/loveLetterText.svg";
import signatures from "../inline-svg/signatures.svg";
import SVG from "react-inlinesvg";

function BrandStoryHero(props) {
  return (
    <section
      className="brandStoryHero"
      style={{ backgroundImage: `url(${LL})` }}
    >
      <div
        className="llText"
        style={{ backgroundImage: `url(${LLText})` }}
      ></div>
      {/* <RibbonHeadline className="brandStoryHero__ribbon" text="Inspired by Italy, Made in America." /> */}
      <div className="loveLetter">
        Bravazzi
        <br />
        is our
        <br />
        Love Letter
        <br />
        to Italy
      </div>
      <div className="brandStoryHero__4ground">
        <img className="cheers" src={AS} />
        <div className="brandStoryHero__sigCont">
          <SVG
            src={signatures}
            uniquifyIDs={true}
            wrapper={React.createFactory("div")}
          />
        </div>
      </div>
    </section>
  );
}

export default BrandStoryHero;
